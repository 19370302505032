import { pollResponce } from '@graphql/poll/poll.query'
import { quizDetail } from '@graphql/quiz/quiz.query'

export const articleDetailData = `
  _id
  dPublishDate
  dPublishDisplayDate
  dModifiedDate
  iEventId
  oDisplayAuthor {
    dCreated
    sDisplayName
    sNumber
    sUName
    sFName
    sUrl
    eDesignation
    _id
    bIsVerified
    oSeo {
      sSlug
    }
  }
  oImg {
    sText
    sCaption
    sAttribute
    sUrl
  }
  oTImg {
    sText
    sCaption
    sAttribute
    sUrl
  }
  oAdvanceFeature {
    bAllowLike
    bAllowComments
    bRequireAdminApproval
    bExclusiveArticle
    bEditorsPick
    bAmp
  }
  sContent
  sAmpContent
  aContent {
    sTagName
    sContent
    sAmpContent
    oProps
    eType
  }
  sDescription
  sSrtTitle
  sSubtitle
  sTitle
  dUpdated
  nCommentCount
  nDuration
  nClaps
  nViewCount
  nOViews
  aPlayer {
    sName
    _id
    eType
    eStatus
    oSeo {
      sSlug
    }
  }
  aSeries {
    sName
    eType
    _id
    oSeo {
      sSlug
    }
    eStatus
  }
  aTags {
    sName
    eType
    _id
    eStatus
    oSeo {
      sSlug
    }
  }
  aVenue {
    sName
    _id
    eStatus
    eType
    oSeo {
      sSlug
    }
  }
  oCategory {
    sName
    _id
    oSeo {
      sSlug
    }
    eStatus
  }
  aTeam {
    sName
    _id
    eType
    eStatus
    oSeo {
      sSlug
    }
  }
  oSeo {
    eType
  }
  oListicleArticle {
    nTotal
    oPageContent
    oAmpPageContent
  }
  aPollId
  aQuiz {
    _id
  }
  aAlsoReadArticles {
    _id
    sTitle
    sSrtTitle
    oSeo {
      sSlug
    }
    oTImg {
      sUrl
      sText
    }
    oImg {
      sUrl
      sText
    }
  }
  bIsListicleArticle
  iBookmarkedId
  bIsBookmarked
  bOld
`

export const articleListData = `
  _id
  sTitle
  sSrtTitle
  oSeo {
    sSlug
    }
  sDescription
  oImg {
    sUrl
    sText
    sCaption
    sAttribute
  }
  nDuration
  dPublishDate
  dPublishDisplayDate
  oCategory {
    sName
    _id
    oSeo {
      sSlug
    }
  }
  oTImg {
    sUrl
    sText
    sCaption
    sAttribute
  }
`

export const videoArticleData = `
  _id
  aThumbnails {
    sKey
    sUrl
  }
  oSeo {
    sSlug
  }
  dPublishDate
  sDuration
  sTitle
  sDescription
  nDurationSeconds
  sThumbnailUrl
  sThumbnailHeight
  sThumbnailWidth
`
export const liveArticleListData = `
  aResults {
    aReaction {
      nCount
      sUniCode
    }
    _id
    dPublishDate
    eStatus
    eType
    oAuthor {
      sFName
    }
    iPollId
    oPoll {
      ${pollResponce}
    }
    oQuiz {
      ${quizDetail}
    }
    iEventId
    sContent
    sAmpContent
    sTitle
    sEventId
    dCreated
    dEndDate
    dUpdated
  }
`
export const liveArticleEventData = `
    oEvent {
      oMatch {
          nLatestInningNumber
          sSubtitle
          oVenue {
            sLocation
          }
          oTeamScoreA {
            oTeam {
              oImg {
                sUrl
                sText
              }
              sAbbr
              sTitle
            }
            sScoresFull
          }
          oTeamScoreB {
            sScoresFull
            oTeam {
              oImg {
                sUrl
              }
              sAbbr
              sTitle
            }
          }
          sStatusStr
          sStatusNote
          sLiveGameStatusStr
          dStartDate
          oSeo {
            sSlug
          }
      }
      sEventName
      _id
      iMatchId
      oTeams {
        oTeamA {
          oFirstInningScore {
            sRun
            sWicket
          }
          oSecondInningScore {
            sRun
            sWicket
          }
          sLogoUrl
          sName
        }
        oTeamB {
          oFirstInningScore {
            sRun
            sWicket
          }
          oSecondInningScore {
            sRun
            sWicket
          }
          sLogoUrl
          sName
        }
        sScoreSummary
        sTeamAovers
        sTeamBovers
      }
      dEventDate
      dEventEndDate
      sLocation
    }
`
